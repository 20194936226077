import './Btn.scss';
import Loader from '../Loader/Loader';

function Btn(props)
{
	const classes = ["Btn"];
	let value = props.value;
	if (props.loading) 
	{
		value = <Loader scale="0.6" color="#121212"/>;
		classes.push("disabled");
	}

	const onClick = () => 
	{
		if (!props.loading) props.onClick();
	}

	return (
		<button className={classes.join(" ")} style={props.style} onClick={onClick}>
			{value}
		</button>
	);
}

export default Btn;