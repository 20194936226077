import "./Auth.scss";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import API from "../../utils/api";
import Ctx from "../../state/Ctx";
import TextInput from "../../components/TextInput/TextInput";
import Btn from "../../components/Btn/Btn";

import Logo from "../../logo.svg";

function Login() 
{
    const { showError, user } = useContext(Ctx);

    if (user) window.location = "/";

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onEmailChange = (e) => setEmail(e.target.value);
    const onPasswordChange = (e) => setPassword(e.target.value);

    const onClick = async () => 
    {
        if (!email) return;
        if (!password) return;
        setIsLoading(true);

        try 
        {
            const data = await API.login(email, password);
            if (data.status === ":)") window.location = "/";
            else 
            {
                showError(data.error);
                setIsLoading(false);
            }
        } 
        catch (err) 
        {
            console.log(err);
            showError("Something went wrong!");
            setIsLoading(false);
        }
    };

    return (
        <div className="Auth">
            <img src={Logo} className="logo" alt="UtilEngine" />
            <h1>Log in to UtilEngine Dashboard</h1>

            <div className="box">
                <h3>Enter email address and password</h3>

                <TextInput
                    required
                    value={email}
                    placeholder="Email address"
                    type="text"
                    onChange={onEmailChange}
                />

                <TextInput
                    required
                    value={password}
                    placeholder="Password"
                    type="password"
                    onChange={onPasswordChange}
                />

                <div className="text-right" style={{ marginTop: "0.5rem" }}>
                    <Link to="/forgot_password" className="a">
                    Forgot your password?
                    </Link>
                </div>

                <Btn
                    style={{ marginTop: "2rem" }}
                    value="Login"
                    loading={isLoading}
                    onClick={onClick}
                />
            </div>
        </div>
    );
}

export default Login;
