import "./Dashboard.scss";
import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Ctx from "../../state/Ctx";
import Header from "../../components/Header/Header";
import Keys from "../Keys/Keys";
import Settings from "../Settings/Settings";

const routes = [
    { path: "/", name: "License", component: <Keys /> },
    { path: "/settings", name: "Settings", component: <Settings /> },
];

function Dashboard() 
{
    const { user } = useContext(Ctx);

    if (!user) window.location = "/login";

    const navRoutes = routes.map((item, index) => {
        return (
            <Route key={index} path={item.path} element={item.component} />
        );
    });

    return (
        <div className="Dashboard">
            <Header routes={routes} />

            <div className="page">
                <div className="container">
                    <div className="page-container">
                        <Routes>
                            {navRoutes}
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
