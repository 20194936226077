import "./Auth.scss";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import API from "../../utils/api";
import Ctx from "../../state/Ctx";
import TextInput from "../../components/TextInput/TextInput";
import Btn from "../../components/Btn/Btn";

import Logo from "../../logo.svg";

function ForgotPassword() 
{
    const { showError, showToast, user } = useContext(Ctx);

    if (user) window.location = "/";

    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onEmailChange = (e) => setEmail(e.target.value);

    const onClick = async () => 
    {
        if (!email) return;
        setIsLoading(true);

        try 
        {
            const data = await API.forgotPassword(email);
            if (data.status === ":)") 
            {
                showToast("Please check your email for reset link!");
                setTimeout(() => window.location = "/", 1500);
            }
            else 
            {
                showError(data.error);
                setIsLoading(false);
            }
        } 
        catch (err) 
        {
            console.log(err);
            showError("Something went wrong!");
            setIsLoading(false);
        }
    };

    return (
        <div className="Auth">
            <img src={Logo} className="logo" alt="UtilEngine" />
            <h1>Log in to UtilEngine Dashboard</h1>

            <div className="box">
                <h3>Enter email address to get password reset link</h3>

                <TextInput
                    required
                    value={email}
                    placeholder="Email address"
                    type="text"
                    onChange={onEmailChange}/>

                <Btn
                    style={{ marginTop: "2rem" }}
                    value="Continue"
                    loading={isLoading}
                    onClick={onClick}/>

                <div style={{ marginTop: "2rem", textAlign: "center" }}>
                    <Link to="/login" className="a">
                        Return to log in
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
