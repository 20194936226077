import './Loader.scss';

function Loader(props)
{
	const style = props.style ? props.style : {};
	if (props.scale) style.transform = `scale(${props.scale})`;

	const ringStyle = {borderColor: `${props.color ? props.color : 'white'} transparent transparent transparent`};
	return (
		<div className="Loader" style={style}>
			<div style={ringStyle}></div>
			<div style={ringStyle}></div>
			<div style={ringStyle}></div>
			<div style={ringStyle}></div>
		</div>
	);
}

export default Loader;