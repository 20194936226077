import "./Auth.scss";
import { useState, useContext, useEffect } from "react";
import API from "../../utils/api";
import Ctx from "../../state/Ctx";
import TextInput from "../../components/TextInput/TextInput";
import Btn from "../../components/Btn/Btn";

import Logo from "../../logo.svg";

function ResetPassword() 
{
    const { showError, showToast, user } = useContext(Ctx);

    if (user) window.location = "/";

    const [userCode, setUserCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => 
    {
        const code = window.location.href.split("u=")[1];
        if (code) setUserCode(code);
        else window.location = "/login";
    }, []);

    const changePassword = (e) => setPassword(e.target.value);
    const changeConfirmPassword = (e) => setConfirmPassword(e.target.value);

    const onClick = async () => 
    {
        if (password.length < 4) return showError("Password should be more than 4 digits!");
        if (password !== confirmPassword) return showError("Password don't match!");
        setIsLoading(true);

        try 
        {
            const data = await API.resetPassword(userCode, password, confirmPassword);
            if (data.status === ":)") 
            {
                showToast("Password reset successful!");
                setTimeout(() => window.location = "/", 1500);
            }
            else 
            {
                showError(data.error);
                setIsLoading(false);
            }
        } 
        catch (err) 
        {
            console.log(err);
            showError("Something went wrong!");
            setIsLoading(false);
        }
    };

    return (
        <div className="Auth">
            <img src={Logo} className="logo" alt="UtilEngine" />
            <h1>Log in to UtilEngine Dashboard</h1>

            <div className="box">
                <h3>Enter your desired password</h3>

                <TextInput
                    value={password}
                    label="New Password"
                    type="password"
                    placeholder="New Password"
                    onChange={changePassword}/>

                <TextInput
                    value={confirmPassword}
                    label="Confirm New Password"
                    type="password"
                    placeholder="Confirm New Password"
                    onChange={changeConfirmPassword}/>

                <Btn
                    style={{ marginTop: "2rem" }}
                    value="Reset Password"
                    loading={isLoading}
                    onClick={onClick}/>
            </div>
        </div>
    );
}

export default ResetPassword;
