import './App.scss';
import {Routes, Route} from 'react-router-dom';
import GlobalState from './state/GlobalState';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';

function App() 
{
	return (
        <GlobalState>
            <div className="App">
                <Routes>
                    <Route path="/*" element={<Dashboard />} />
                    <Route path="login" element={<Login />} />
                    <Route path="forgot_password" element={<ForgotPassword />} />
                    <Route path="reset_password" element={<ResetPassword />} />
                </Routes>
            </div>
        </GlobalState>
    );
}

export default App;
