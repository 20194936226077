import './Keys.scss';
import React, { useState, useEffect, useContext } from 'react';
import API from '../../utils/api';
import Ctx from '../../state/Ctx';
import Btn from '../../components/Btn/Btn';
import { BookSvg, ChevronRightSvg, KeySvg } from "../../components/Icons/Icons";

function Keys(props)
{
    const { showError, showToast, user } = useContext(Ctx);
    const [keys, setKeys] = useState([]);
    const [currentKey, setCurrentKey] = useState(-1);

    useEffect(() => 
    {
        (async () => 
        {
            try 
            {
                const data = await API.keys();
                if (data.status === ":)")
                {
                    setKeys(data.result);
                }
                else showError(data.error);
            }
            catch (err)
            {
                console.log(err);
                showError(err.message);
            }
        })();
    }, []);

    const deactivate = async (deviceToken) => 
    {
        const newKeys = JSON.parse(JSON.stringify(keys));
        delete newKeys[currentKey].devices[deviceToken];
        setKeys(newKeys);
        
        try 
        {
            const data = await API.deactivate(keys[currentKey].id, deviceToken);
            if (data.status === ":)") showToast("Device deactivated successfully!");
            else showError(data.error);
        }
        catch (err)
        {
            console.log(err);
            showError(err.message);
        }
    }

    return (
        <div className="Keys">
            <div className="section">
                <div className="heading">
                    <BookSvg />
                    <h1>License keys</h1>
                </div>
                <div className="content">
                    <ul className="key-list">
                        {
                            keys.map((item, index) => 
                            {
                                const active = currentKey !== -1 ? keys[currentKey].id === item.id : false;
                                return (
                                    <li
                                        key={index}
                                        onClick={() => setCurrentKey(index)}
                                        active={`${active}`}>
                                        <div className="key-info">
                                            <h3>{item.id.substring(0, 25)}...</h3>
                                            <span>{user.email}</span>
                                        </div>
                                        <span className="activation-count">
                                            {Object.keys(item.devices).length}
                                        </span>
                                        <ChevronRightSvg />
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>


            <div className="section key-section">
                <div className="heading">
                    {currentKey !== -1 ? (
                        <React.Fragment>
                            <KeySvg />
                            <h1>{keys[currentKey].id}</h1>
                        </React.Fragment>
                    ) : null}
                </div>
                <div className="content">
                    {currentKey !== -1 ? (
                        <React.Fragment>
                            <div className="activations">
                                <h3>Activations</h3>
                                {
                                    Object.keys(keys[currentKey].devices).length ? 
                                    Object.keys(keys[currentKey].devices).map((item, index) => 
                                    {
                                        const device = keys[currentKey].devices[item];

                                        return (
                                            <div className="activation box" key={index}>
                                                <div className="details">
                                                <h3>{device.device_name}</h3>
                                                <ul>
                                                    <li>
                                                        <b>Activated on:</b> {new Date(device.date).toDateString()}
                                                    </li>
                                                    <li>
                                                        <b>Platform:</b> {device.os}
                                                    </li>
                                                    <li>
                                                        <b>Browser:</b> {device.browser}
                                                    </li>
                                                    <li>
                                                        <b>Platform Version:</b> {device.os_version}
                                                    </li>
                                                    <li>
                                                        <b>Browser Version:</b> {device.browser_version}
                                                    </li>
                                                </ul>
                                                </div>

                                                <div className="actions">
                                                    <Btn
                                                        value="Deactivate"
                                                        style={{ backgroundColor: "#e53935" }}
                                                        onClick={(e) => deactivate(item)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }) : 
                                    <div></div>
                                }
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>

        </div>
    );
}

export default Keys;