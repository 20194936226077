import Axios from 'axios';
import Urls from './urls';
import LocalStorage from './localStorage';

function getToken()
{
    return LocalStorage.get("token");
}

function getAuthHeader()
{
    const token = LocalStorage.get("token");
    const headers = {
        authorization: `Bearer ${token}`
    };

    return headers 
}

async function checkup()
{
    try
    {
        const {data} = await Axios.get(Urls.CHECKUP, { headers: getAuthHeader() });
        if (data.status === ":)") LocalStorage.set("token", data.result.token);
        return data;
    }
    catch (err)
    {
        throw err;
    }
}

async function login(email, password)
{
    try 
    {
        const params = { email, password };
        const { data } = await Axios.post(Urls.LOGIN, params, { withCredentials: true });
        if (data.status === ":)") LocalStorage.set("token", data.result.token);
        return data;            
    } 
    catch (err) 
    {
        throw err;
    }
}

async function forgotPassword(email)
{
    try
    {
        const params = { email };
        const {data} = await Axios.post(Urls.FORGOT, params, { headers: getAuthHeader() });
        return data;
    }
    catch (err)
    {
        throw err;
    }
}

async function resetPassword(code, newPassword, confirmPassword)
{
    try
    {
        const params = { code, new_password: newPassword, confirm_password: confirmPassword };
        const {data} = await Axios.post(Urls.RESET, params, { headers: getAuthHeader() });
        return data;
    }
    catch (err)
    {
        throw err;
    }
}

async function keys()
{
    try
    {
        const {data} = await Axios.get(Urls.KEYS, { headers: getAuthHeader() });
        return data;
    }
    catch (err)
    {
        throw err;
    }
}

async function deactivate(key, deviceToken)
{
    try
    {
        const params = { key, device_token: deviceToken };
        const {data} = await Axios.post(Urls.KEY_DEACTIVATE, params, { headers: getAuthHeader() });
        return data;
    }
    catch (err)
    {
        throw err;
    }
}

async function saveSettings(name, newsletter)
{
    try
    {
        const params = { name, newsletter };
        const {data} = await Axios.post(Urls.SAVE_SETTINGS, params, { headers: getAuthHeader() });
        return data;
    }
    catch (err)
    {
        throw err;
    }
}

async function newPassword(password, newPassword, confirmPassword)
{
    try
    {
        const params = { password, new_password: newPassword, confirm_password: confirmPassword };
        const {data} = await Axios.post(Urls.NEW_PASSWORD, params, { headers: getAuthHeader() });
        return data;
    }
    catch (err)
    {
        throw err;
    }
}

async function logout()
{
    LocalStorage.remove("token");
}

const API = {
    getToken,
    checkup,
    login,
    forgotPassword,
    resetPassword,
    keys,
    deactivate,
    saveSettings,
    newPassword,
    logout
}

export default API;