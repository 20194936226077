import './Checkbox.scss';
import {CheckCircleSvg, CircleSvg} from '../Icons/Icons';

function Checkbox(props)
{	
	let svg = <CircleSvg />;
	if (props.checked) svg = <CheckCircleSvg />;
	return (
		<div className="Checkbox" onClick={props.onChange}>
			{svg}
			<label>{props.label}</label>
		</div>
	);
}

export default Checkbox;