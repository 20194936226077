import './Toast.scss';
import {CancelSvg} from '../Icons/Icons';

function Toast(props)
{
	const options = props.options;

	const classes = ["Toast"];
	if (options.active) classes.push("active");
	if (options.error) classes.push("error");

	const close = () => 
	{
		if (options.active) props.close();
	}

	return (
		<div className={classes.join(" ")}>
			<span>{options.message}</span>
			<div onClick={close}>
				<CancelSvg />
			</div>
		</div>
	);
}

export default Toast;