// const baseUrl = "http://localhost:5000/dashboard";
const baseUrl = "https://api.utilengine.com/dashboard";

const Urls = {
    LOGIN: `${baseUrl}/login`,
    CHECKUP: `${baseUrl}/checkup`,
    KEYS: `${baseUrl}/keys`,
    KEY_DEACTIVATE: `${baseUrl}/key/deactivate`,
    SAVE_SETTINGS: `${baseUrl}/settings/save`,
    NEW_PASSWORD: `${baseUrl}/settings/password/new`,
    FORGOT: `${baseUrl}/forgot`,
    RESET: `${baseUrl}/reset`
};

export default Urls;