import './Settings.scss';
import React, { useState, useContext } from 'react';
import API from '../../utils/api';
import Ctx from '../../state/Ctx';
import Btn from '../../components/Btn/Btn';
import TextInput from '../../components/TextInput/TextInput';
import Checkbox from '../../components/Checkbox/Checkbox';
import { SettingsSvg, LockSvg } from "../../components/Icons/Icons";

function Settings(props)
{
    const { showError, showToast, user } = useContext(Ctx);
    const [name, setName] = useState(user.name);
    const [newsletter, setNewsletter] = useState(user.newsletter);
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const changeName = (e) => setName(e.target.value);
    const changeNewsletter = () => setNewsletter(!newsletter);
    const changeOldPassword = (e) => setOldPassword(e.target.value);
    const changeNewPassword = (e) => setNewPassword(e.target.value);
    const changeConfirmPassword = (e) => setConfirmPassword(e.target.value);

    const save = async () =>
    {   
        setLoading("save");
        try 
        {
            const data = await API.saveSettings(name, newsletter);
            if (data.status === ":)") showToast("Settings saved!");
            else showError(data.error);

            setLoading(false);
        }
        catch (err)
        {
            console.log(err);
            showError(err.message);
            setLoading(false);
        }
    }

    const changePassword = async () =>
    {
        if (oldPassword.length < 4) return showError("Old password is incorrect!");
        if (newPassword.length < 4) return showError("Password should be more than 4 digits!");
        if (newPassword !== confirmPassword) return showError("Password don't match!");

        setLoading("change");
        try 
        {
            const data = await API.newPassword(oldPassword, newPassword, confirmPassword);
            if (data.status === ":)") showToast("Password changed!");
            else showError(data.error);

            setLoading(false);
        }
        catch (err)
        {
            console.log(err);
            showError(err.message);
            setLoading(false);
        }
    }

    return (
        <div className="Settings">
            <div className="heading">
                <SettingsSvg />
                <h1>Settings</h1>
            </div>
            <div className="content">
                <TextInput
                    value={name}
                    label="Name"
                    placeholder="Full Name"
                    onChange={changeName}/>

                <Checkbox
                    checked={newsletter}
                    label="Receive mail about updates, new products and deals, don't worry we won't spam."
                    onChange={changeNewsletter}/>

                <div className="btn-wrapper">
                    <Btn
                        value="Save"
                        style={{ marginTop: "2rem", width: "160px" }}
                        loading={loading === "save"}
                        onClick={save}/>
                </div>

                <div className="change-password">
                    <div className="change-password-heading">
                        <LockSvg />
                        <h3 style={{ marginLeft: ".5rem" }}>Change Password</h3>
                    </div>

                    <TextInput
                        value={oldPassword}
                        label="Old Password"
                        type="password"
                        placeholder="Old Password"
                        onChange={changeOldPassword}/>

                    <TextInput
                        value={newPassword}
                        label="New Password"
                        type="password"
                        placeholder="New Password"
                        onChange={changeNewPassword}/>

                    <TextInput
                        value={confirmPassword}
                        label="Confirm New Password"
                        type="password"
                        placeholder="Confirm New Password"
                        onChange={changeConfirmPassword}/>

                    <Btn
                        value="Change Password"
                        style={{
                            marginTop: "2rem",
                            width: "160px",
                        }}
                        loading={loading === "change"}
                        onClick={changePassword}/>
                </div>
            </div>
        </div>
    );
}

export default Settings;