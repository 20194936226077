import "./Header.scss";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import API from '../../utils/api';
import Ctx from "../../state/Ctx";
import Logo from "../../logo.svg";

function Header(props) 
{
    const [ currentPath, setCurrentPath ] = useState(window.location.pathname);

    const { user } = useContext(Ctx);

    const nav = props.routes.map((item, index) => 
    {    
        let active = "";
        if (currentPath === item.path || currentPath === `${item.path}/`) active = "active";
        return (
            <Link to={item.path} className={`a ${active}`} key={index}>
                <li onClick={() => setCurrentPath(item.path)}>{item.name}</li>
            </Link>
        );
    });

    const logout = async () => {
        try
        {
            await API.logout();
            window.location = "/login";
        }
        catch (err)
        {
            console.log(err);
        }
    };

    return (
    <div className="Header">
        <div className="container">
            <div className="navbar">
                <img src={Logo} className="logo" alt="hoverify" />
                <h1>{user.name}'s dashboard</h1>

                <ul className="navigation">
                    {nav}
                    <li className="logout" onClick={logout}>
                        Logout
                    </li>
                </ul>
            </div>
        </div>
    </div>
    );
}

export default Header;
