import "./TextInput.scss";

function TextInput(props) {
  let label = null,
    message = null;
  if (props.label) label = <label>{props.label}</label>;
  if (props.message) message = <div className="message">{props.message}</div>;
  return (
    <div className="TextInput" style={props.style}>
      {label}
      {message}
      <input
        ref={props.ref}
        value={props.value}
        defaultValue={props.defaultValue}
        type={props.type}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        onChange={props.onChange}
        required={props.required}
      />
    </div>
  );
}

export default TextInput;
