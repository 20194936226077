import React from 'react';
import API from '../utils/api';
import Ctx from './Ctx';
import Toast from '../components/Toast/Toast';
import Loader from '../components/Loader/Loader';

class GlobalState extends React.Component
{
	state = {
		user: null,
		toast: {
			active: false,
			error: false,
			message: ""
		},
		loading: true
	};

	async componentDidMount()
	{
		if (!API.getToken()) return this.setState({ loading: false }); 
		try
		{
			const data = await API.checkup();
			if (data.status === ":)")
			{
				this.setState({ 
					user: { 
						id: data.result.id,
						name: data.result.name,
						email: data.result.email,
						newsletter: data.result.newsletter
					} 
				});
			}
			
			this.setState({ loading: false });
		}
		catch (err)
		{
			console.log(err);
			this.setState({ loading: false });
		}
	}

	render()
	{
		if (this.state.loading)
		{
			return (
				<div style={
					{
						width: '100%',
						height: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}
				}>
					<Loader scale="1.2"/>
				</div>
			)
		}

		return (
			<Ctx.Provider
				value={
					{
						...this.state,
						saveSettings: this.saveSettings,
						showError: this.showError,
						showToast: this.showToast
					}
				}>
				<Toast options={this.state.toast} close={this.closeToast}/>
				{this.props.children}
			</Ctx.Provider>
		);
	}

	saveSettings = (name, subscription) => 
	{
		this.setState(prevState => 
		{
			const user = prevState.user;
			user.name = name;
			return {user, subscription};
		});
	}

	showError = (message) => 
	{
		if (this.state.toast.active) clearTimeout(this.toastTimeout);

		this.setState({
			toast: {
				active: true,
				error: true,
				message
			}
		});

		this.toastTimeout =  setTimeout(() => 
		{
			this.setState({
				toast: {
					active: false,
					error: true,
					message: ""
				}
			})
		}, 3000);
	}

	showToast = (message) => 
	{
		if (this.state.toast.active) clearTimeout(this.toastTimeout);

		this.setState({
			toast: {
				active: true,
				error: false,
				message
			}
		});

		this.toastTimeout =  setTimeout(() => 
		{
			this.setState({
				toast: {
					active: false,
					error: false,
					message: ""
				}
			})
		}, 3000);
	}

	closeToast = () => 
	{
		clearTimeout(this.toastTimeout);
		this.setState(prevState => {
			return {
				toast: {
					active: false,
					error: prevState.toast.error,
					message: prevState.toast.message
				}
			}
		});
	}
}

export default GlobalState;